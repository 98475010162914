/**
 * Main colors
 * #228FD8 - blue
 * #D86B22 - orange
 * #C622D8 - pink
 * #34D822 - green
 * #D82234 - red
 * #D4AF37 - gold
 * #59abea - LoL team blue
 * #a2482d - LoL team red
 */

body {
  margin: 0;
  overflow-y: scroll;
  color: black;
  font-family: 'Lato', sans-serif;
  font-size: calc(10px + 1vmin);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #228fd8;
  display: flex;
  flex-direction: column;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img {
  max-width: 100%;
  height: auto;
}

a, a:link, a:visited, a:active {
  color: #228fd8;
  text-decoration: none;
}
a:focus, a:hover {
  opacity: 0.8;
}

button:focus {
  outline: none;
  opacity: 0.8;
}

h1 {
  font-size: 2em;
}

h2 {
  font-size: 1.8em;
}

h3 {
  font-size: 1.5em;
}

h4 {
  font-size: 1.2em;
}

.showOnMobile {
  display: none;
}
@media (max-width: 768px) {
  .hideOnMobile {
    display: none;
  }
  .showOnMobile {
    display: inline-block;
  }
}

.navbar {
  font-weight: bold;
  background: #0b0b0b;
  border-bottom: 1px solid #333;
  word-spacing: .1em;
  min-height: 62px;
}

.navbar-nav {
  align-items: center;
}

.navbar-toggler {
  border: none;
}

#basic-navbar-nav a,
#basic-navbar-nav span {
  margin: 1em;
  padding: 0;
  font-size: .8rem;
  color: white;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
}
#basic-navbar-nav:focus,
#basic-navbar-nav:hover,
#basic-navbar-nav a:focus,
#basic-navbar-nav a:hover,
#basic-navbar-nav span:focus,
#basic-navbar-nav span:hover {
  color: #228fd8;
  opacity: 1;
}

a.logo {
  margin: -0.1em 1em 0 0;
}

.active.dropdown.nav-item #basic-nav-dropdown {
  color: #228fd8;
  opacity: 1;
}

.dropdown-menu {
  width: 12em;
  background: #228fd8;
  font-weight: normal;
}

.dropdown-menu.account-dropdown {
  left: -90px;
}

#basic-navbar-nav .dropdownNavLink a,
#basic-navbar-nav .dropdownNavLink span {
  width: 100%;
  margin: 0;
  padding: 0.5em 1em;
  text-align: left;
}
#basic-navbar-nav .dropdownNavLink a:focus,
#basic-navbar-nav .dropdownNavLink a:hover,
#basic-navbar-nav .dropdownNavLink span:focus,
#basic-navbar-nav .dropdownNavLink span:hover {
  color: white;
  background: rgba(0, 0, 0, 0.2);
}

@media (max-width: 992px) {
  #basic-navbar-nav .dropdownNavLink a,
  #basic-navbar-nav .dropdownNavLink span {
    text-align: center;
  }

  a.logo {
    margin: 0;
  }

  #basic-nav-dropdown {
    text-align: center;
  }
}

.leftSidebar a {
  color: white;
  text-transform: uppercase;
  text-decoration: none;
}
.leftSidebar a:focus,
.leftSidebar a:hover {
  background: #228fd8;
  opacity: 1;
}
a.oeTwoPointOh:focus,
a.oeTwoPointOh:hover {
  background: #D86B22 !important;
}

.sidebarSocialIcons a:focus,
.sidebarSocialIcons a:hover {
  color: #228fd8;
  background: none;
}

.mainContent {
  padding: 2em;
}

/* For styling Wordpress-imported content */
table, tbody {
  border: 1px solid black;
  margin: 1em 0;
}
tr {
  border: 1px solid black;
}
td {
  border-left: 1px dashed black;
  border-right: 1px dashed black;
}

.alignleft {
  float: left;
  margin-right: 1em;
}

.alignright {
  float: right;
  margin-left: 1em;
}

.more-link {
  display: none;
}

.embed-youtube {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}

.embed-youtube iframe {
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
}

/**
 * CSS spinner from loading.io
 */
.lds-dual-ring-small {
  display: inline-block;
  width: 40px;
  height: 40px;
}
.lds-dual-ring-small:after {
  content: " ";
  display: block;
  width: 32px;
  height: 32px;
  margin: 4px;
  border-radius: 50%;
  border: 3px solid black;
  border-color: black transparent black transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring-small {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid black;
  border-color: black transparent black transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.lds-dual-ring-large {
  display: inline-block;
  width: 160px;
  height: 160px;
}
.lds-dual-ring-large:after {
  content: " ";
  display: block;
  width: 128px;
  height: 128px;
  margin: 164px;
  border-radius: 50%;
  border: 12px solid black;
  border-color: black transparent black transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring-large {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Date picker fullscreen takeover CSS */
.DayPicker {
  padding-bottom: 0.75em;
}

.CalendarMonth tr, .CalendarMonth td {
  border: none;
}

.DateRangePickerInput__withBorder {
  border: none;
}

.DateInput_input {
  font-size: 1.4em;
  font-weight: normal;
  line-height: 1em;
  color: #777;
  padding: 0 5px;
  text-align: center;
}

.DateInput_input__focused {
  border-bottom: 1px solid #228fd8;
}

.DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-right: 33px solid #228fd8;
}
.DayPickerKeyboardShortcuts_show__bottomRight:hover::before {
  border-right: 33px solid rgba(34,143,216,0.8);
}

.DateRangePickerInput_arrow_svg {
  fill: #777;
  height: 1.3em;
  width: 1.3em;
}

.CalendarDay, .CalendarDay__default, .CalendarDay__default:hover {
  border: none;
}

.CalendarDay__selected_span {
  background: rgba(34,143,216,0.8);
  color: white;
  border: none;
}

.CalendarDay__selected {
  background: #228fd8;
  border: none;
  color: white;
}

.CalendarDay__selected:hover, .CalendarDay__selected_span:hover {
  background: #228fd8;
  border: none;
  color: white;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: #228fd8;
  border: none;
  color: white;
}

.CalendarMonth_caption {
  color: #228fd8;
  font-size: 1.3rem;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}